import React, { useState } from "react";
import { connect } from "react-redux";
import { login } from "../../store/actions/auth";

import loginImage from "../../assets/images/auth/loginImage.svg";
import { ReactComponent as MainLogoDark } from "../../assets/images/main/mainLogoDark.svg";
import { ReactComponent as Mail } from "../../assets/images/icons/mail.svg";
import { ReactComponent as Lock } from "../../assets/images/icons/lock.svg";

import "../../styles/auth.scss";
import styles from "./auth.module.scss";
import Checkbox from "../../components/common/form/Checkbox";
import InputField from "../../components/common/form/InputField";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FORGOT_PASSWORD_PATH, HOME_DASHBOARD_PATH } from "../../routes/paths";
import { validateLogin } from "../../validations/login-validations";
import { useEffect } from "react";
import Loader from "../../components/common/Loader";
import { clearErrors } from "../../store/actions/errors";
import InputError from "../../components/common/form/InputError";

const Login = (props) => {
  const { errors, validateLogin, clearErrors } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [userCreds, setUserCreds] = useState({
    grant_type: "client_credentials",
    client_id: "",
    client_secret: "",
  });
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState();
  // re render on errors
  useEffect(() => {}, [errors]);
  // clear errors on unmount
  useEffect(() => {
    return () => {
      clearErrors();
    };
  }, []);

  const handleUserCreds = (e) => {
    const { name, value } = e.target;

    setUserCreds((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (validateLogin(userCreds, t)) {
      setIsLoading(true);
      props?.login(userCreds, async (status) => {
        setIsLoading(false);
        if (status === 200) {
          navigate(
            location?.state?.prevLocation
              ? location?.state?.prevLocation
              : HOME_DASHBOARD_PATH
          );
        }
      });
    }
  };

  return (
    <div className="auth_container">
      <div className="auth_innerContaienr">
        <div className="auth_innerContaienr_leftCol">
          <img src={loginImage} alt="" srcSet="" />
        </div>
        <div className="auth_innerContaienr_rightCol">
          <MainLogoDark />
          {t("login_title")}
          <div className={`${styles.login_formContainer}`}>
            {errors?.status === 401 && (
              <div className={`${styles.unauth_error_container}`}>
                <InputError
                  error={"Invalid email or password. Please try again."}
                />
              </div>
            )}
            <InputField
              type="email"
              name="client_id"
              id="client_id"
              placeholder={t("login_email_placeholder")}
              value={userCreds.client_id}
              onChange={handleUserCreds}
              error={errors?.error}
              icon={<Mail />}
            />

            <InputField
              type="password"
              name="client_secret"
              id="client_secret"
              value={userCreds.client_secret}
              onChange={handleUserCreds}
              placeholder={t("login_password_placeholder")}
              error={errors?.error}
              icon={<Lock />}
            />
            <div className={`${styles.login_form_control}`}>
              <Checkbox
                id="rememberMe"
                name="rememberMe"
                label={t("login_rememberMe")}
              />
            </div>
          </div>
          <button
            type="submit"
            onClick={onSubmit}
            className={`btn_secondary ${styles.login_btn}`}
          >
            <Loader isLoading={isLoading}>{t("login_btnText")}</Loader>
          </button>
          <div className="form_control">
            <Link
              to={FORGOT_PASSWORD_PATH}
              className={`${styles.forgot_password_link}`}
            >
              {t("login_forgotPassword_link")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  errors: state.errors,
});
export default connect(mapStateToProps, { login, validateLogin, clearErrors })(
  Login
);
