import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import DashboardNavigate from "../../../../components/common/DashboardNavigate";
import Table from "../../../../components/common/Table";
import MessagesTable from "../../../../components/customer-dashboard/postoffice/dashboard/MessagesTable";
import { messagesTable } from "../../../../helper/dummyData/table";
import { MessageContext } from "../../../../routes/dashboard/customer-dashboard-routes/sub-routes/postoffice/MessagePostofficeRoute";

const MessagesPostoffice = () => {
  const { t, i18n } = useTranslation();

  const {
    itemsPerPage,
    messages,
    currentPage,
    handleItemsPerPage,
    setCurrentPage,
    setCurrentOrderBy,
    orderBy,
  } = useContext(MessageContext);
  return (
    <div>
      <h2>{t("dashboardNavigator_link_messages")}</h2>
      <DashboardNavigate />
      <Table
        itemsPerPage={itemsPerPage}
        messages={messages}
        currentPage={currentPage}
        handleItemsPerPage={handleItemsPerPage}
        totalCount={messages?.messages?.totalCount}
        handleCurrentPage={setCurrentPage}
      >
        <MessagesTable
          data={messages?.messages?.items}
          setCurrentOrderBy={setCurrentOrderBy}
          orderBy={orderBy}
        />
      </Table>
    </div>
  );
};

export default MessagesPostoffice;
