import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const SidebarLinks = (props) => {
  const {
    currentLink,
    handleCurrentLink,
    icon,
    title,
    link,
    titleImg,
    sidebarToggle,
  } = props;
  const setQueryTitle = (queryTitle) => {
    const queryTitleLow = queryTitle?.toLowerCase();
    const query = queryTitleLow?.replace(/ /g, "_");
    return query;
  };
  const { t, i18n } = useTranslation();

  return (
    <Fragment>
      <Link
        to={`/${setQueryTitle(link)}`}
        className={
          currentLink.toLowerCase().replace("/dashboard", "") ===
          link.toLowerCase()
            ? "sidebar_link active"
            : "sidebar_link"
        }
      >
        <div className="sidebar_link_icon">{icon}</div>
        {!sidebarToggle && (
          <span>
            {titleImg ? titleImg : t(`sidebar_link_${title.toLowerCase()}`)}
          </span>
        )}
      </Link>
    </Fragment>
  );
};

export default SidebarLinks;
