import React from "react";

const Checkbox = (props) => {
  const { id, name, label } = props;
  return (
    <div className="form_control">
      <input id={id} name={name} className="input_checkbox" type="checkbox" />
      <label className="checkbox_label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
