import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";

import { connect, useSelector } from "react-redux";
import { LOGIN_PATH } from "../paths";
import { USER_TOKEN } from "../../helper/constants";
const cookies = new Cookies();

const PrivateRoute = (props) => {
  const location = useLocation();
  if (!cookies.get(USER_TOKEN)) {
    return (
      <Navigate to={LOGIN_PATH} state={{ prevLocation: location.pathname }} />
    );
  }

  // authorized so return child components
  return props?.children;
};
const mapStateToProps = (state) => ({
  user: state.user.user,
});
export default connect(mapStateToProps)(PrivateRoute);
