import { dispatchErrors } from "../store/actions/errors";

const emailValidate = (email) => {
  return email.toLowerCase().match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
};
export const validateLogin = (data, t) => (dispatch) => {
  if (!emailValidate(data?.client_id) && data.client_secret.length <= 0) {
    dispatch(
      dispatchErrors({
        error: {
          client_id: t("login_validate_email_msg"),
          client_secret: t("login_validate_password_msg"),
        },
      })
    );
    return false;
  } else if (!emailValidate(data?.client_id)) {
    dispatch(
      dispatchErrors({
        error: {
          client_id: t("login_validate_email_msg"),
        },
      })
    );
    return false;
  } else if (data.client_secret.length <= 0) {
    dispatch(
      dispatchErrors({
        error: {
          client_secret: t("login_validate_password_msg"),
        },
      })
    );
    return false;
  }
  return true;
};
