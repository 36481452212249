import React, { createContext, useEffect, useState } from "react";
import MessagesPostoffice from "../../../../../pages/customer-dashboard/post-office/MessagesPostoffice";
import { useQuery } from "react-query";
import axios from "axios";
import { get_messages } from "../../../../../store/actions/graphqlApi/dashboard";
import Loader from "../../../../../components/common/Loader";

export const MessageContext = createContext();

const MessagePostofficeRoute = (props) => {
  const [messages, setMessages] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderBy, setCurrentOrderBy] = useState("id:DESC");

  const handleItemsPerPage = (value) => {
    setItemsPerPage(value);
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: "messages",
    queryFn: () => {
      return get_messages(itemsPerPage, currentPage, orderBy, setMessages);
    },
  });

  useEffect(() => {
    refetch();
  }, [itemsPerPage, currentPage, orderBy]);

  return (
    <div>
      <Loader isLoading={isLoading} bgColor={"#000A37"} size="100px">
        <MessageContext.Provider
          value={{
            messages,
            itemsPerPage,
            currentPage,
            orderBy,
            handleItemsPerPage,
            setCurrentPage,
            setCurrentOrderBy,
          }}
        >
          <MessagesPostoffice />
        </MessageContext.Provider>
      </Loader>
    </div>
  );
};

export default MessagePostofficeRoute;
