import React from "react";
import { useTranslation } from "react-i18next";
import pageNotFoundImage from "../../assets/images/main/page-not-found.svg";
import styles from "./page-not-found.module.scss";
const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.pageNotFound_container}`}>
      <div className={`${styles.pageNotFound_innerContainer}`}>
        <img src={pageNotFoundImage} alt="" srcset="" />
        <div className={`${styles.pageNotFound_404_text}`}>
          {t("pageNotFound_404_text")}
        </div>
        <h2 className={`${styles.pageNotFound_title_text}`}>
          {t("pageNotFound_title_text")}
        </h2>
        <div className={`${styles.pageNotFound_description}`}>
          {t("pageNotFound_description")}
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
