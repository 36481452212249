import axios from "axios";
import Cookies from "universal-cookie";
import { USER_TOKEN } from "../helper/constants";
const cookies = new Cookies();

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const getAuthToken = () => {
  return cookies.get(USER_TOKEN);
};
