import React, { useState } from "react";
import styles from "./cards-home-dashboard.module.scss";
import { ReactComponent as Chevron } from "../../../../assets/images/dashboard/home/home_card_chevron.svg";
import { useTranslation } from "react-i18next";
const CardHomeDashboard = (props) => {
  const { data, handlePopupDetails, handlePopup } = props;
  const { t, i18n } = useTranslation();
  const [showDetail, setShowDetail] = useState(false);

  const handleButton = () => {
    let details;
    if (data.title === "Townhall") {
      details = t("homeCard_townhallPopup");
    } else if (data.title === "CityHawk") {
      details = t("homeCard_cityhawkPopup");
    } else {
      details = t("homeCard_defaulPopup");
    }
    handlePopupDetails(details);
    handlePopup();
  };

  return (
    <div
      className={styles.home_card}
      style={{
        height: showDetail ? "540px" : "258px",
      }}
    >
      <div
        className={styles.home_card_toggler}
        style={{ transform: showDetail ? "rotate(0)" : "rotate(180deg)" }}
        onClick={() => setShowDetail((prev) => !prev)}
      >
        <Chevron />
      </div>
      <div className={`${styles.home_card_content}`}>
        <div>
          <div className={styles.home_card_img}>
            <img src={data?.icon} alt="" srcSet="" />
          </div>
        </div>
        <h4 className={styles.home_card_title}>{data?.title}</h4>
        <div className={`${styles.home_card_paragraph_wrapper}`}>
          {showDetail && (
            <p className={styles.home_card_paragraph}>{data?.description}</p>
          )}{" "}
        </div>
      </div>
      <div className={styles.home_card_status}>
        {data?.status === "active" ? (
          <button className="btn_primary">
            {t("homeCard_activated_btnText")}
          </button>
        ) : (
          <button className="btn_secondary" onClick={handleButton}>
            {t("homeCard_interested_btnText")}
          </button>
        )}
      </div>
    </div>
  );
};

export default CardHomeDashboard;
