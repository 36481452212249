import React from "react";
import styles from "./loader.module.scss";
const Loader = (props) => {
  const { isLoading, children, bgColor, size } = props;
  if (!isLoading) return children;
  return (
    <div className={`${styles.spinner_container}`}>
      <div
        className={`${styles.loading_spinner}`}
        style={{
          borderColor: bgColor ? bgColor : "#f3f3f3",
          width: size ? size : "20px",
          height: size ? size : "20px",
        }}
      ></div>
    </div>
  );
};

export default Loader;
