import React, { useState } from "react";
import styles from "./cards-home-dashboard.module.scss";
import CardHomeDashboard from "./CardHomeDashboard";
import Popup from "../../../common/Popup";
import ThankyouPopup from "../ThankyouPopup.js";
const CardsHomeDashboard = (props) => {
  const { data } = props;
  const [popupShow, setPopupShow] = useState(false);
  const [popupDetails, setPopDetails] = useState("");
  const handlePopup = () => {
    setPopupShow((prev) => !prev);
  };
  return (
    <div className={styles.home_cards_container}>
      {data?.map((item, index) => (
        <CardHomeDashboard
          key={index}
          data={item}
          handlePopupDetails={setPopDetails}
          handlePopup={handlePopup}
        />
      ))}
      {popupShow && (
        <Popup
          handlePopup={handlePopup}
          contentStyle={{
            width: "355px",
            //   height: "285px",
          }}
        >
          <ThankyouPopup
            popupDetails={popupDetails}
            handlePopup={handlePopup}
          />
        </Popup>
      )}
    </div>
  );
};

export default CardsHomeDashboard;
