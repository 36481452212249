import React from "react";
import { useState } from "react";
import { chart1Data } from "../../../../../helper/dummyData/chart";
import CustomAreaChart from "../../../../common/charts/CustomAreaChart";
import styles from "./area-chart.module.scss";
import { ReactComponent as DropdownIcon } from "../../../../../assets/images/icons/chevron-sm.svg";

const AreaChartContainer = (props) => {
  const { chartBG, id, data, title, total } = props;
  const [showByDropdown, setShowByDropdown] = useState(false);
  const [currentChartType, setCurrentChartType] = useState("Weekly");
  return (
    <div className={`${styles.area_chart_container}`}>
      <div className={`${styles.chart_header}`}>
        <div className={`${styles.chart_title}`}>{title}</div>
        {/* Uncomment after deployment */}
        {/* <div className={`${styles.chart_header_dropdown}`}>
          <div
            className={`${styles.chart_header_dropdown_title}`}
            onClick={() => setShowByDropdown((prev) => !prev)}
          >
            <span>{currentChartType}</span>

            <span>
              <DropdownIcon width={11} height={11} />
            </span>
          </div>
          {showByDropdown && (
            <div className={`${styles.chart_header_dropdown_content}`}>
              <div
                className={`${styles.chart_header_dropdown_content_item} ${
                  currentChartType === "Weekly" ? styles.active : ""
                }`}
                onClick={() => {
                  setCurrentChartType("Weekly");
                  setShowByDropdown((prev) => !prev);
                }}
              >
                Weekly
              </div>
              <div
                className={`${styles.chart_header_dropdown_content_item} ${
                  currentChartType === "Monthly" ? styles.active : ""
                }`}
                onClick={() => {
                  setCurrentChartType("Monthly");
                  setShowByDropdown((prev) => !prev);
                }}
              >
                Monthly
              </div>
            </div>
          )}
        </div> */}
      </div>
      <div className={`${styles.chart_current_value}`}>{total}</div>
      <div className={`${styles.chart_wrapper}`} dir="ltr">
        <CustomAreaChart data={data} chartBG={chartBG} id={id} />
      </div>
    </div>
  );
};

export default AreaChartContainer;
