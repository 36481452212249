import React from "react";
import { ReactComponent as HamMenu } from "../../../assets/images/icons/ham-menu.svg";
import { ReactComponent as Setting } from "../../../assets/images/icons/setting.svg";
import { ReactComponent as Bell } from "../../../assets/images/icons/bell.svg";
import { ReactComponent as User } from "../../../assets/images/icons/user.svg";
import { ReactComponent as NavbarLogoSM } from "../../../assets/images/main/navbarLogoSm.svg";

import "./navbar.scss";
import { useTranslation } from "react-i18next";
const Navbar = (props) => {
  const { handleSidebarToggle } = props;
  const { t } = useTranslation();
  return (
    <div className="navbar">
      <div className="dashboard_container navbar_container">
        <div className="navbar_wrapper">
          <div className="navbar_wrapper_leftCol">
            <div className="ham_icon" onClick={handleSidebarToggle}>
              <HamMenu width={24} height={24} />
            </div>
            <div className={`navbar_logo_sm`}>
              <NavbarLogoSM />
            </div>
          </div>
          <div className="navbar_wrapper_rightCol">
            {/* <div className="navbar_wrapper_rightCol_icon_wrapper">
              <span className="navbar_img_wrapper">
                <Setting />
              </span>
              <span className="navbar_img_wrapper">
                <Bell />
              </span>
            </div> */}
            <div className="navbar_splitter"></div>
            <div className="navbar_user_details">
              <div className="navbar_user_data">
                <h6 className="navbar_user_data_name">Khaleel Jibran</h6>
                <div className="navbar_user_data_role">
                  {t("AdminSupplier")}
                </div>
              </div>
              <div className="user_image">
                <User />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
