import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as HomeIcon } from "../../../assets/images/dashboard/homeIcon.svg";
import { ReactComponent as PostOfficeIcon } from "../../../assets/images/dashboard/postOfficeIcon.svg";
import { ReactComponent as IceBankIcon } from "../../../assets/images/dashboard/iceBankIcon.svg";
import { ReactComponent as PostofficeTitleImage } from "../../../assets/images/dashboard/layout/sidebar/postofficeTitleImage.svg";
import { ReactComponent as IcebankTitleImage } from "../../../assets/images/dashboard/layout/sidebar/icebankTitleImage.svg";

import Sidebar from "../../../components/layout/Sidebar";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../../../components/layout/Navbar";
import Footer from "../../../components/layout/Footer";
import { useTranslation } from "react-i18next";

const DashboardRoute = (props) => {
  const { t, i18n } = useTranslation();

  const [sideBarLinks] = useState([
    {
      title: "Home",
      link: "home",
      icon: <HomeIcon width={24} height={24} />,
    },
    {
      title: "postoffice",
      link: "postoffice",
      icon: <PostOfficeIcon width={24} height={24} />,
      titleImg: <PostofficeTitleImage />,
      dropdown: [
        { title: "Dashboard", link: "dashboard" },
        { title: "Messages", link: "messages" },
      ],
    },
    // Remove comments after deployement
    // {
    //   title: "icebank",
    //   link: "icebank",
    //   icon: <IceBankIcon width={24} height={24} />,
    //   titleImg: <IcebankTitleImage />,
    //   dropdown: [{ title: "Dashboard", link: "dashboard" }],
    // },
  ]);

  const [currentLink, setCurrentLink] = useState("");
  const [sidebarToggle, setSidebarToggle] = useState(false);
  let location = useLocation();

  const handleCurrentLink = (linkName) => {
    setCurrentLink(linkName);
  };

  const handleSidebarToggle = () => {
    setSidebarToggle((prev) => !prev);
  };
  useEffect(() => {
    setCurrentLink(location?.pathname.slice(1));
  }, [location]);

  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  useEffect(() => {
    if (dimensions?.width < 1440) {
      setSidebarToggle(true);
    } else {
      setSidebarToggle(false);
    }
  }, [dimensions?.width]);

  const sidebar = useRef();

  useEffect(() => {
    if (sidebarToggle) {
      if (dimensions?.width >= 1280) {
        sidebar.current.style.display = "block";
        document.documentElement.style.setProperty("--sidebar-width", "100px");
        document.documentElement.style.setProperty(
          "--dashboard-container-width",
          "100px"
        );
      } else {
        // document.documentElement.style.setProperty("--sidebar-width", "0");
        sidebar.current.style.display = "none";
        document.documentElement.style.setProperty(
          "--dashboard-container-width",
          "0px"
        );
      }
    } else {
      if (dimensions?.width >= 1440) {
        document.documentElement.style.setProperty(
          "--dashboard-container-width",
          "258px"
        );
        document.documentElement.style.setProperty("--sidebar-width", "258px");
      } else if (dimensions?.width <= 1440 && dimensions?.width >= 1280) {
        document.documentElement.style.setProperty(
          "--dashboard-container-width",
          "100px"
        );
        document.documentElement.style.setProperty("--sidebar-width", "258px");
      } else if (dimensions?.width < 1280) {
        sidebar.current.style.display = "block";

        document.documentElement.style.setProperty(
          "--dashboard-container-width",
          "0px"
        );
        document.documentElement.style.setProperty("--sidebar-width", "258px");
      }
    }
  }, [sidebarToggle, dimensions]);

  return (
    <div>
      <Navbar handleSidebarToggle={handleSidebarToggle} />
      <Sidebar
        sidebar={sidebar}
        currentLink={currentLink}
        handleCurrentLink={handleCurrentLink}
        sideBarLinks={sideBarLinks}
        sidebarToggle={sidebarToggle}
        handleSidebarToggle={handleSidebarToggle}
      />
      <div className="main_dashboard">
        <div className="dashboard_container">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DashboardRoute;
