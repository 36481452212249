import "../../styles/popup.scss";

import ReactDom from "react-dom";

const Popup = (props) => {
  function closeModalDone(e) {
    e.stopPropagation();
    props.handlePopup();
  }

  return ReactDom.createPortal(
    <>
      <div className="popup" onClick={closeModalDone}>
        <div className="popup_content" onClick={(e) => e.stopPropagation()}>
          <div className="popup_content_container" style={props?.contentStyle}>
            {props.children}
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portals")
  );
};

export default Popup;
