import React from "react";
import { ReactComponent as FooterLogo } from "../../../assets/images/main/footerLogo.svg";
import "./footer.scss";
const Footer = () => {
  return (
    <div className="dashboard_container">
      <div className="footer_content">
        &copy; <FooterLogo /> {new Date().getFullYear()} ice city portal
      </div>
    </div>
  );
};

export default Footer;
