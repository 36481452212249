import React, { useEffect } from "react";
import SidebarLinks from "./SidebarLinks";
import "./sidebar.scss";
import { ReactComponent as MainLogo } from "../../../assets/images/main/mainLogo.svg";
import { ReactComponent as SmallLogo } from "../../../assets/images/main/smallLogo.svg";

import SidebarDropdown from "./SidebarDropdown";

const Sidebar = (props) => {
  const {
    currentLink,
    handleCurrentLink,
    sideBarLinks,
    sidebarToggle,
    handleSidebarToggle,
    sidebar,
  } = props;

  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  return (
    <div
      className={`sidebar ${sidebarToggle && "sidebar_toggle"}`}
      ref={sidebar}
    >
      {!sidebarToggle && dimensions?.width < 1440 && (
        <div className="close_side_bar" onClick={handleSidebarToggle}>
          &times;
        </div>
      )}
      <div className={`sidebar_brand_logo`}>
        {sidebarToggle ? <SmallLogo /> : <MainLogo />}
      </div>
      <div className={`sidebar_links`}>
        {sideBarLinks.map((link, index) =>
          link?.dropdown ? (
            <SidebarDropdown
              key={index}
              currentLink={currentLink}
              sidebarToggle={sidebarToggle}
              handleCurrentLink={handleCurrentLink}
              icon={link.icon}
              title={link.title}
              link={link.link}
              titleImg={link.titleImg}
              dropdown={link.dropdown}
            />
          ) : (
            <SidebarLinks
              sidebarToggle={sidebarToggle}
              key={index}
              currentLink={currentLink}
              handleCurrentLink={handleCurrentLink}
              icon={link.icon}
              title={link.title}
              link={link.link}
              titleImg={link.titleImg}
            />
          )
        )}
      </div>
    </div>
  );
};

export default Sidebar;
