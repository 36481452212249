import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DashboardNavigate from "../../components/common/DashboardNavigate";
import Popup from "../../components/common/Popup";
import CardsHomeDashboard from "../../components/customer-dashboard/home/CardsHomeDashboard";
import postoffice from "../../assets/images/dashboard/home/postoffice.svg";
import icebank from "../../assets/images/dashboard/home/icebank.svg";
import townhall from "../../assets/images/dashboard/home/townhall.svg";
const Home = () => {
  const { t, i18n } = useTranslation();

  const [homeDashboardCards, setHomeDashboardCards] = useState([
    {
      icon: postoffice,
      title: t("homeCard_postofficeTitle"),
      status: "active",
      description: t("homeCard_postofficeDesc"),
    },
    // {
    //   icon: icebank,
    //   title: t("homeCard_icebankTitle"),
    //   status: "active",
    //   description: t("homeCard_icebankDesc"),
    // },
    // {
    //   icon: townhall,
    //   title: t("homeCard_townhallTitle"),
    //   status: "pending",
    //   description: t("homeCard_townhallDesc"),
    // },
    // {
    //   icon: postoffice,
    //   title: t("homeCard_cityHawkTitle"),
    //   status: "pending",
    //   description: t("homeCard_cityhawkDesc"),
    // },
  ]);
  return (
    <div>
      <h2>{t("home_dashboard_title")}</h2>
      <DashboardNavigate />
      <CardsHomeDashboard data={homeDashboardCards} />
    </div>
  );
};

export default Home;
