import React from "react";
import { connect } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { USER_TOKEN } from "../../helper/constants";
import { HOME_DASHBOARD_PATH } from "../paths";
const AuthLayout = (props) => {
  const cookies = new Cookies();
  const location = useLocation();
  if (!cookies.get(USER_TOKEN)) {
    return <Outlet />;
  }
  return (
    <Navigate
      to={
        location?.state?.prevLocation
          ? location?.state?.prevLocation
          : HOME_DASHBOARD_PATH
      }
    />
  );
};
const mapStateToProps = (state) => ({
  user: state.user.user,
});
export default connect(mapStateToProps)(AuthLayout);
