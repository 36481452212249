import { localDateTime } from "../../../helper/browser-date";

export const generate_weekly_query = () => {
  const now = localDateTime(new Date());
  let startHours;
  let endHours;
  let query = ``;
  for (let i = 7; i > 0; i--) {
    startHours = new Date(now.getTime() - i * 24 * 60 * 60 * 1000);
    endHours = new Date(now.getTime() - i * 24 * 60 * 60 * 1000);

    startHours.setHours(0, 0, 0, 0);
    endHours.setHours(23, 59, 59, 59);

    query += `
      day${i}: messages(  
        where: {and:[ {creationDate: { gte: "${startHours.toISOString()}" }} {creationDate: { lte: "${endHours.toISOString()}" }}  ] }
      ) {
        totalCount,
        
      },
      `;
  }

  return query;
};
