import React, { useState } from "react";
import { connect } from "react-redux";
import { login } from "../../store/actions/auth";

import loginImage from "../../assets/images/auth/loginImage.svg";
import { ReactComponent as MainLogoDark } from "../../assets/images/main/mainLogoDark.svg";
import { ReactComponent as Mail } from "../../assets/images/icons/mail.svg";

import "../../styles/auth.scss";
import styles from "./auth.module.scss";
import InputField from "../../components/common/form/InputField";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LOGIN_PATH } from "../../routes/paths";

const ForgotPassword = (props) => {
  const [userCreds, setUserCreds] = useState({
    grant_type: "client_credentials",
    client_id: "",
    client_secret: "",
  });
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const handleUserCreds = (e) => {
    const { name, value } = e.target;

    setUserCreds((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSubmit = (e) => {
    e.preventDefault();

    props?.login(userCreds, setIsLoading);
  };
  return (
    <div className="auth_container">
      <div className="auth_innerContaienr">
        <div className="auth_innerContaienr_leftCol">
          <img src={loginImage} alt="" srcSet="" />
        </div>
        <div className="auth_innerContaienr_rightCol">
          <MainLogoDark />
          {t("forgotPassword_title")}
          <div className={`${styles.login_formContainer}`}>
            <InputField
              type="email"
              name="client_id"
              id="client_id"
              placeholder={t("forgotPassword_email_placeholder")}
              value={userCreds.client_id}
              onChange={handleUserCreds}
              icon={<Mail />}
            />
          </div>
          <button
            type="submit"
            onClick={onSubmit}
            className={`btn_secondary ${styles.login_btn}`}
          >
            {t("forgotPassword_resetPassword_btnText")}
          </button>
          <div className="form_control">
            {t("forgotPassword_loginLink_description")}{" "}
            <Link className={`${styles.login_link}`} to={LOGIN_PATH}>
              {t("forgotPassword_loginLink_title")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { login })(ForgotPassword);
