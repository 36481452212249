import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import chevron from "../../../assets/images/icons/chevron.svg";
const SidebarDropdown = (props) => {
  const {
    currentLink,
    handleCurrentLink,
    icon,
    title,
    link,
    titleImg,
    dropdown,
    sidebarToggle,
  } = props;

  const location = useLocation();
  const [dropdownIsActive, setDropdownIsActive] = useState(
    location?.pathname?.includes(link)
  );
  const setQueryTitle = (queryTitle) => {
    const queryTitleLow = queryTitle?.toLowerCase();
    const query = queryTitleLow?.replace(/ /g, "_");
    return query;
  };
  const { t, i18n } = useTranslation();

  return (
    <div className="sidebar_link_dropdown">
      <div
        className={
          // currentLink.toLowerCase() === title.toLowerCase()
          (dropdownIsActive && !sidebarToggle) ||
          location?.pathname?.includes(link)
            ? "sidebar_link active"
            : "sidebar_link"
        }
        onClick={() => setDropdownIsActive((prev) => !prev)}
      >
        <div className="sidebar_link_icon">{icon}</div>
        {!sidebarToggle && (
          <div className="sidebar_link_dropdown_title">
            {titleImg ? titleImg : t(`sidebar_link_${title.toLowerCase()}`)}
            <img
              className="sidebar_link_dropdown_chevron"
              style={{
                opacity: dropdownIsActive ? 1 : 0.5,
                transform: `rotate(${dropdownIsActive ? "0" : "180deg"})`,
              }}
              src={chevron}
              alt=""
              srcSet=""
            />
          </div>
        )}
      </div>

      {dropdownIsActive && !sidebarToggle && (
        <div className={`sidebar_link_dropdown_content`}>
          {dropdown.map((item, index) => (
            <Link
              key={index}
              to={`/${setQueryTitle(link)}/${setQueryTitle(item?.link)}`}
              className={`sidebar_link_dropdown_content_item ${
                currentLink.toLowerCase() ===
                  `${setQueryTitle(link)}/${setQueryTitle(item?.link)}` &&
                "active"
              }  `}
            >
              {t(`sidebar_link_${item?.title.toLowerCase()}`)}
            </Link>
          ))}
        </div>
      )}
      {sidebarToggle && (
        <div className="sidebar_link_dropdown_sm_container">
          <div className="sidebar_link_dropdown_title">
            {titleImg ? titleImg : t(`sidebar_link_${title.toLowerCase()}`)}
          </div>
          <div className={`sidebar_link_dropdown_content`}>
            {dropdown.map((item, index) => (
              <Link
                key={index}
                to={`/${setQueryTitle(link)}/${setQueryTitle(item?.link)}`}
                className={`sidebar_link_dropdown_content_item ${
                  currentLink.toLowerCase() ===
                    `${setQueryTitle(link)}/${setQueryTitle(item?.link)}` &&
                  "active"
                }  `}
              >
                {t(`sidebar_link_${item?.title.toLowerCase()}`)}
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SidebarDropdown;
