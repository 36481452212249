import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Whatsapp } from "../../../../assets/images/dashboard/home/Whatsapp.svg";
import styles from "./thankyou-popup.module.scss";
const ThankyouPopup = (props) => {
  const { popupDetails, handlePopup } = props;
  const { t, i18n } = useTranslation();

  return (
    <div className={`${styles.thankyou_popup_container}`}>
      <div className={`${styles.thankyou_popup_content}`}>
        <div className={`${styles.thankyou_popup_image_wrapper}`}>
          <Whatsapp />
        </div>
        <div className={`${styles.thankyou_popup_description}`}>
          {popupDetails}
        </div>
      </div>
      <div className={`${styles.thankyou_popup_btn_wrapper}`}>
        <button className="btn_secondary" onClick={handlePopup}>
          {t("homeCard_thankyouPopup_close_btnText")}
        </button>
      </div>
    </div>
  );
};

export default ThankyouPopup;
