import axios from "axios";
import { localDateTime } from "../../../helper/browser-date";
import { dispatchErrors } from "../errors";
import { generate_weekly_query } from "./queries";

export const get_messages =
  (itemsPerPage, currentPage, orderBy, setMessages) => (dispatch) => {
    return axios
      .post(`/graphql`, {
        query: `
    {
      messages(
        take: ${itemsPerPage}
        skip: ${currentPage * itemsPerPage}
        order: {${orderBy}}
      ) {
        totalCount
        items {
          id
          destination
          statusCode
          content
          creationDate
          response {
            postMessageId
            rawResponse
            creationDate
          }
        }
      }
    }
  `,
      })
      .then((res) => setMessages(res.data.data))
      .catch((err) => dispatch(dispatchErrors(err.response)));
  };

export const get_messages_cards_data = (setData) => (dispatch) => {
  const today = localDateTime(new Date());
  today.setHours(0, 0, 0, 0);
  const month = localDateTime(new Date());
  month.setDate(1);
  month.setHours(0, 0, 0, 0);
  return axios
    .post(`/graphql`, {
      query: `{
            today:messages(
              take: 10
              skip: 0
              order: {id:DESC}
              where: { creationDate: { gte: "${today.toISOString()}" } }
            ) {
              totalCount
              
            },
            month:messages(
              take: 10
              skip: 0
              order: {id:DESC}
              where: { creationDate: { gte: "${month.toISOString()}" } }
            ) {
              totalCount
              
            }
            total:messages(
              take: 10
              skip: 0
              order: {id:DESC}
            ) {
              totalCount
            }
            
          }`,
    })
    .then((res) => setData({ data: res.data.data, lastUpdated: new Date() }))
    .catch((err) => dispatch(dispatchErrors(err.response)));
};

export const get_messages_graph_data = (type, handleData) => (dispatch) => {
  let query = generate_weekly_query();

  return axios
    .post(`/graphql`, {
      query: `{
            ${query}
            }`,
    })
    .then((res) => {
      handleData(res?.data?.data);
    })
    .catch((err) => dispatch(dispatchErrors(err.response)));
};
