import axios from "axios";
import Cookies from "universal-cookie";
import { USER_TOKEN } from "../../helper/constants";
import { getAuthToken, setAuthToken } from "../../utils/authToken";
import { dispatchErrors } from "./errors";
const cookies = new Cookies();

export const login = (data, callback) => (dispatch) => {
  let status;
  axios
    .post("/connect/token", data, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
    .then((res) => {
      let token = res?.data?.access_token;
      if (token) {
        cookies.set(USER_TOKEN, token, { path: "/" });
        setAuthToken(token);
        status = res.status;
      }
    })
    .catch((err) => {
      dispatch(dispatchErrors(err?.response?.data));
      status = err?.response?.status;
    })
    .finally(() => {
      callback(status);
    });
};
export const setCurrentUser = (handleLoading) => (dispatch) => {
  handleLoading(true);
  let token = getAuthToken();
  if (token) {
    setAuthToken(token);
    // set current user here
  }
  handleLoading(false);
};
