import React, { Fragment } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import styles from "./dashboard-navigate.module.scss";
import { ReactComponent as Chevron } from "../../../assets/images/icons/chevron_left.svg";
import { useTranslation } from "react-i18next";

const DashboardNavigate = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  let links = location?.pathname?.slice(1).split("/");

  const getLinksPath = (index) => {
    return links?.slice(0, index + 1).join("/");
  };
  return (
    <div className={`${styles.navigation_container}`}>
      {links?.map((link, index) =>
        index === 0 ? (
          <div key={index} className={`${styles.navigate_link}`}>
            {t(`dashboardNavigator_link_${link}`)}
          </div>
        ) : (
          <Fragment key={index}>
            <Chevron
              style={{
                transform:
                  document.body.dir === "rtl" ? "rotate(180deg)" : "rotate(0)",
              }}
            />
            <Link
              className={`${styles.navigate_link} ${styles.clickable}`}
              to={`/${getLinksPath(index)}`}
            >
              {t(`dashboardNavigator_link_${link}`)}
            </Link>
          </Fragment>
        )
      )}
    </div>
  );
};

export default DashboardNavigate;
