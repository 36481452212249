import React, { useEffect, useState } from "react";
import "../../../styles/custom-chart.scss";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const CustomAreaChart = (props) => {
  const { data, chartBG, id } = props;

  const [chartTheme, setChartTheme] = useState("#000000");
  useEffect(() => {
    getComputedStyle(document.documentElement).getPropertyValue(chartBG);

    setChartTheme(
      getComputedStyle(document.documentElement).getPropertyValue(chartBG)
        ? getComputedStyle(document.documentElement).getPropertyValue(chartBG)
        : "#000000"
    );
  }, []);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={730}
        height={250}
        data={data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id={`colorUv${id}`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={`${chartTheme}`} stopOpacity={0.9} />
            <stop offset="95%" stopColor={`${chartTheme}00`} stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" />
        <YAxis
          tickFormatter={(tick) => {
            return tick > 1000 ? tick / 1000 + "K " : tick;
          }}
        />
        <CartesianGrid vertical={false} />
        <Tooltip
          labelFormatter={() => ""}
          formatter={(okay) => [
            new Intl.NumberFormat("en").format(okay),
            "messages",
          ]}
        />
        <Area
          type="line"
          dataKey="uv"
          stroke={`${chartTheme}`}
          fillOpacity={1}
          fill={`url(#colorUv${id})`}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default CustomAreaChart;
