import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const initialState = {};
const middleware = [thunk];
let composeInit;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  composeInit = compose(
    applyMiddleware(...middleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  composeInit = compose(applyMiddleware(...middleware));
}

let store = createStore(rootReducer, initialState, composeInit);

export default store;
