import "./App.scss";
import { Navigate, Route, Routes } from "react-router-dom";
import DashboardCustomerRoute from "./routes/dashboard/customer-dashboard-routes/DashboardCustomerRoute";
import Home from "./pages/customer-dashboard/Home";
import "./styles/dashboard.scss";
import DashboardPostofficeRoute from "./routes/dashboard/customer-dashboard-routes/sub-routes/postoffice/DashboardPostofficeRoute";
import MessagePostofficeRoute from "./routes/dashboard/customer-dashboard-routes/sub-routes/postoffice/MessagePostofficeRoute";
import { useEffect } from "react";
import { setCurrentUser } from "./store/actions/auth";
import { useState } from "react";
import PrivateRoute from "./routes/PrivateRoute";
import { connect } from "react-redux";
import AuthLayout from "./routes/auth/AuthLayout";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import i18n from "./i18n";
import {
  HOME_DASHBOARD_PATH,
  ICEBANK_DASHBOARD_PATH,
  LOGIN_PATH,
  POSTOFFICE_DASHBOARD_PATH,
  POSTOFFICE_MESSAGES_PATH,
  FORGOT_PASSWORD_PATH,
} from "./routes/paths";
import PageNotFound from "./pages/PageNotFound";

function App(props) {
  const homePaths = ["/home", "/"];
  const postOfficePath = ["/postoffice"];
  const icebank = ["/icebank"];

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    props?.setCurrentUser(setIsLoading);
    document.body.dir = i18n.dir();
  }, []);

  return (
    <div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Routes>
          {/* Home Paths */}
          <Route element={<DashboardCustomerRoute />}>
            {homePaths?.map((item, index) => (
              <Route
                key={index}
                path={item}
                element={<Navigate to={HOME_DASHBOARD_PATH} />}
              />
            ))}
            <Route
              path={HOME_DASHBOARD_PATH}
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />

            {/* Postoffice Paths */}
            {postOfficePath?.map((item, index) => (
              <Route
                key={index}
                path={item}
                element={<Navigate to={POSTOFFICE_DASHBOARD_PATH} />}
              />
            ))}
            <Route
              path={POSTOFFICE_DASHBOARD_PATH}
              element={
                <PrivateRoute>
                  <DashboardPostofficeRoute />
                </PrivateRoute>
              }
            />
            <Route
              path={POSTOFFICE_MESSAGES_PATH}
              element={
                <PrivateRoute>
                  <MessagePostofficeRoute />
                </PrivateRoute>
              }
            />

            {/* Icebank Routes */}
            {icebank?.map((item, index) => (
              <Route
                key={index}
                path={item}
                element={<Navigate to={ICEBANK_DASHBOARD_PATH} />}
              />
            ))}
            <Route
              path={ICEBANK_DASHBOARD_PATH}
              element={
                <PrivateRoute>
                  <div>Icebank Dashboard</div>
                </PrivateRoute>
              }
            />
            <Route
              path="*"
              element={
                <PrivateRoute>
                  <PageNotFound />{" "}
                </PrivateRoute>
              }
            />
          </Route>
          <Route element={<AuthLayout />}>
            <Route path={LOGIN_PATH} element={<Login />} />
            <Route path={FORGOT_PASSWORD_PATH} element={<ForgotPassword />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      )}
    </div>
  );
}

export default connect(null, { setCurrentUser })(App);
