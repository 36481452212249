import React, { useContext, useState } from "react";
import styles from "./table.module.scss";
import { ReactComponent as SortableIcon } from "../../../assets/images/icons/sortable.svg";
import { MessageContext } from "../../../routes/dashboard/customer-dashboard-routes/sub-routes/postoffice/MessagePostofficeRoute";
import { useEffect } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
const Table = (props) => {
  const {
    totalCount,
    handleItemsPerPage,
    itemsPerPage,
    currentPage,
    handleCurrentPage,
  } = props;

  const [numberOfPages, setNumberOfPages] = useState();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setNumberOfPages(Math.ceil(totalCount / itemsPerPage));
  }, [totalCount, itemsPerPage]);

  const renderNumberofPages = () => {
    let temp = [];
    for (let i = 0; i < numberOfPages; i++) {
      temp.push(
        <div
          onClick={() => handleCurrentPage(i)}
          className={`${styles.table_pagination_page_btn} ${
            currentPage === i ? styles.active : ""
          }`}
          key={i + 1}
        >
          {i + 1}
        </div>
      );
    }
    return temp;
  };

  const renderPagination = () => {
    if (numberOfPages > 1) {
      return (
        <div className={`${styles.table_pagination_container}`}>
          <div
            className={`${styles.table_prev_btn}`}
            onClick={() =>
              handleCurrentPage((prev) => (prev > 0 ? prev - 1 : 0))
            }
          >
            {t("table_pagination_prev_btnText")}
          </div>
          {/* <div className={`${styles.table_pagination_page_btn_container}`}>
            {renderNumberofPages()}
          </div> */}
          <div
            className={`${styles.table_next_btn}`}
            onClick={() =>
              handleCurrentPage((prev) =>
                prev < numberOfPages - 1 ? prev + 1 : numberOfPages - 1
              )
            }
          >
            {t("table_pagination_next_btnText")}
          </div>
        </div>
      );
    }
  };
  return (
    <div className={`${styles.table_container}`}>
      <div className={`${styles.table_table_header}`}>
        <div className={`form_control`}>
          <label htmlFor="items_per_page">
            {t("table_shows_row_per_page")}{" "}
          </label>
          <select
            name="items_per_page"
            id="items_per_page"
            className={`${styles.items_per_page_select}`}
            onChange={(e) => handleItemsPerPage(e.target.value)}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
          </select>
        </div>
      </div>
      <table className={`${styles.table}`}>{props.children}</table>
      <div className={`${styles.table_pagination}`}>{renderPagination()}</div>
    </div>
  );
};

export default Table;
