import React from "react";
import DashboardNavigate from "../../../../components/common/DashboardNavigate";
import { ReactComponent as PostofficeHeading } from "../../../../assets/images/dashboard/postOfficeHeading.svg";
import PostofficeDashboardCards from "../../../../components/customer-dashboard/postoffice/dashboard/PostofficeDashboardCards";
import { chart1Data, chart2Data } from "../../../../helper/dummyData/chart";

import AreaChartContainer from "../../../../components/customer-dashboard/postoffice/dashboard/AreaChartContainer";

import icon1 from "../../../../assets/images/icons/Mail_Open.svg";
import icon2 from "../../../../assets/images/icons/Coins.svg";
import styles from "./dashboard-postoffice.module.scss";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  get_messages_cards_data,
  get_messages_graph_data,
} from "../../../../store/actions/graphqlApi/dashboard";
import { useQuery } from "react-query";
import { useEffect } from "react";

const DashboardPostoffice = () => {
  const { t, i18n } = useTranslation();
  const [messagesData, setMessagesData] = useState();
  const [messagesGraphData, setMessagesGraphData] = useState();
  const [messagesAreaChartData, setMessagesAreaChartData] = useState([]);

  const [postOfficeMessages, setPostOfficeMessages] = useState();
  const [postOfficeExpenses, setPostOfficeExpenses] = useState();

  // Messages Cards Query
  const messagesCard = useQuery({
    queryKey: "messages_cards",
    queryFn: () => {
      return get_messages_cards_data(setMessagesData);
    },
  });

  // Messages Graph Query
  const messagesGraph = useQuery({
    queryKey: "messages_graph",
    queryFn: () => {
      return get_messages_graph_data("weekly", handleMessagesGraphData);
    },
  });

  const handleMessagesGraphData = (data) => {
    const now = new Date();
    const weekday = ["S", "M", "T", "W", "T", "F", "S"];

    let sum = 0;
    for (let key in data) {
      let i = +key.replace("day", "");
      let date = new Date(now.getTime() - i * 24 * 60 * 60 * 1000);
      data[key].date = weekday[date.getDay()];
      sum += +data[key].totalCount;
    }
    setMessagesGraphData({ data, total: sum });
  };
  useEffect(() => {
    if (messagesData) {
      // set messages
      setPostOfficeMessages([
        {
          icon: icon1,
          title: t("postoffice_card_messages_today_title"),
          cost: `${messagesData?.data?.today?.totalCount}`,
          time: `${t(
            "postoffice_card_lastUpdated_text"
          )} ${messagesData?.lastUpdated?.toLocaleString()}`,
        },
        {
          icon: icon1,
          title: t("postoffice_card_messages_thisMonth_title"),
          cost: `${messagesData?.data?.month?.totalCount}`,
          time: `${t(
            "postoffice_card_lastUpdated_text"
          )} ${messagesData?.lastUpdated?.toLocaleString()}`,
        },
        {
          icon: icon1,
          title: t("postoffice_card_messages_total_title"),
          cost: `${messagesData?.data?.total?.totalCount}`,
          time: `${t(
            "postoffice_card_lastUpdated_text"
          )} ${messagesData?.lastUpdated?.toLocaleString()}`,
        },
      ]);

      // set expenses
      setPostOfficeExpenses([
        {
          icon: icon2,
          title: t("postoffice_card_expenses_today_title"),
          cost: `${messagesData?.data?.today?.totalCount * 5}`,
          time: `${t(
            "postoffice_card_lastUpdated_text"
          )} ${messagesData?.lastUpdated?.toLocaleString()}`,
        },
        {
          icon: icon2,
          title: t("postoffice_card_expenses_thisMonth_title"),
          cost: `${messagesData?.data?.month?.totalCount * 5}`,
          time: `${t(
            "postoffice_card_lastUpdated_text"
          )} ${messagesData?.lastUpdated?.toLocaleString()}`,
        },
        {
          icon: icon2,
          title: t("postoffice_card_expenses_total_title"),
          cost: `${messagesData?.data?.total?.totalCount * 5}`,
          time: `${t(
            "postoffice_card_lastUpdated_text"
          )} ${messagesData?.lastUpdated?.toLocaleString()}`,
        },
      ]);
    }
  }, [messagesData]);

  useEffect(() => {
    if (messagesGraphData) {
      let temp = [];
      for (let key in messagesGraphData.data) {
        temp.push({
          name: messagesGraphData.data[key].date,
          uv: messagesGraphData.data[key].totalCount,
        });
      }
      setMessagesAreaChartData(temp);
    }
  }, [messagesGraphData]);

  return (
    <div>
      <PostofficeHeading />
      <DashboardNavigate />
      <PostofficeDashboardCards
        data={postOfficeMessages}
        iconBG={"var(--secondary-bg)"}
      />
      {/* <PostofficeDashboardCards
        data={postOfficeExpenses}
        iconBG={"var(--primary-bg)"}
      /> */}
      <div className={`${styles.dashboard_chart_container}`}>
        <AreaChartContainer
          title={t("postoffice_card_graph_title_messagesProcessed")}
          data={messagesAreaChartData}
          total={messagesGraphData?.total}
          chartBG={"--secondary-bg"}
          id="1"
        />
        {/* <AreaChartContainer
          title={t("postoffice_card_graph_title_expense")}
          data={chart2Data}
          total={messagesGraphData?.total}
          chartBG={"--primary-bg"}
          id="2"
        /> */}
      </div>
    </div>
  );
};

export default DashboardPostoffice;
