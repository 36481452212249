import React, { Fragment, useContext } from "react";
import styles from "../../../common/Table/table.module.scss";
import { ReactComponent as SortableIcon } from "../../../../assets/images/icons/sortable.svg";
import { useTranslation } from "react-i18next";
import { localDateTime } from "../../../../helper/browser-date";

const MessagesTable = (props) => {
  const { data, setCurrentOrderBy, orderBy } = props;
  const { t, i18n } = useTranslation();

  const handleStatus = (status) => {
    if (status === 200) {
      return getComputedStyle(document.documentElement).getPropertyValue(
        "--success-color"
      );
    } else {
      return getComputedStyle(document.documentElement).getPropertyValue(
        "--error-color"
      );
    }
  };

  const handleOrberBy = (value) => {
    setCurrentOrderBy((prev) => {
      if (value === prev) {
        if (prev.includes("ASC")) {
          return prev.replace("ASC", "DESC");
        } else {
          return prev.replace("DESC", "ASC");
        }
      }
      return value;
    });
  };
  return (
    <Fragment>
      <thead className={`${styles.table_head}`}>
        <tr className={`${styles.table_row}`}>
          <th
            className={`${styles.table_heading}`}
            onClick={() => handleOrberBy("id:ASC")}
          >
            {t("postoffice_messages_table_id")}
            <span className={`${styles.sortableIcon}`}>
              <SortableIcon />
            </span>
          </th>
          <th
            className={`${styles.table_heading}`}
            onClick={() => handleOrberBy("destination:ASC")}
          >
            {t("postoffice_messages_table_destination")}
            <span className={`${styles.sortableIcon}`}>
              <SortableIcon />
            </span>
          </th>
          <th
            className={`${styles.table_heading}`}
            onClick={() => handleOrberBy("statusCode:ASC")}
          >
            {t("postoffice_messages_table_status")}
            <span className={`${styles.sortableIcon}`}>
              <SortableIcon />
            </span>
          </th>
          <th
            className={`${styles.table_heading}`}
            onClick={() => handleOrberBy("content:ASC")}
          >
            {t("postoffice_messages_table_content")}
            <span className={`${styles.sortableIcon}`}>
              <SortableIcon />
            </span>
          </th>
          <th
            className={`${styles.table_heading}`}
            onClick={() => handleOrberBy("response:{rawResponse:ASC}")}
          >
            {t("postoffice_messages_table_response")}
            <span className={`${styles.sortableIcon}`}>
              <SortableIcon />
            </span>
          </th>
          <th
            className={`${styles.table_heading}`}
            onClick={() => handleOrberBy("content:DESC")}
          >
            {t("postoffice_messages_table_createdAt")}
            <span className={`${styles.sortableIcon}`}>
              <SortableIcon />
            </span>
          </th>
          <th
            className={`${styles.table_heading}`}
            onClick={() => handleOrberBy("response:{creationDate:ASC}")}
          >
            {t("postoffice_messages_table_responseAt")}
            <span className={`${styles.sortableIcon}`}>
              <SortableIcon />
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item) => (
          <tr key={item?.id}>
            <td data-label={t("postoffice_messages_table_id")}>
              {" "}
              <div className={`${styles.table_data_innerContent}`}>
                {item?.id}{" "}
              </div>
            </td>
            <td data-label={t("postoffice_messages_table_destination")}>
              {" "}
              <div className={`${styles.table_data_innerContent}`}>
                {item?.destination}{" "}
              </div>
            </td>
            <td data-label={t("postoffice_messages_table_status")}>
              <div className={`${styles.table_data_innerContent}`}>
                <div
                  style={{ background: handleStatus(item?.statusCode) }}
                  className={`${styles.status_icon}`}
                ></div>
                <div>{item?.statusCode === 200 ? "Success" : "Error"}</div>
              </div>
            </td>
            <td data-label={t("postoffice_messages_table_content")}>
              <div className={`${styles.table_data_innerContent}`}>
                {item?.content}
              </div>
            </td>
            <td data-label={t("postoffice_messages_table_response")}>
              <div className={`${styles.table_data_innerContent}`}>
                {item?.response?.rawResponse}
              </div>
            </td>
            <td data-label={t("postoffice_messages_table_createdAt")}>
              <div className={`${styles.table_data_innerContent}`}>
                {localDateTime(new Date(item?.creationDate)).toLocaleString()}
              </div>
            </td>
            <td data-label={t("postoffice_messages_table_responseAt")}>
              <div className={`${styles.table_data_innerContent}`}>
                {localDateTime(
                  new Date(item?.response?.creationDate)
                ).toLocaleString()}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Fragment>
  );
};

export default MessagesTable;
