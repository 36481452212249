import React from "react";
import DashboardPostoffice from "../../../../../pages/customer-dashboard/post-office/DashboardPostoffice";

const DashboardPostOfficeRoute = () => {
  return (
    <div>
      <DashboardPostoffice />
    </div>
  );
};

export default DashboardPostOfficeRoute;
