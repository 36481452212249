import React from "react";
import styles from "./postoffice-dashboard-card.module.scss";
const PostofficeDashboardCard = (props) => {
  const { data, iconBG } = props;
  return (
    <div className={`${styles.cards_container}`}>
      {data?.map((item, index) => (
        <div key={index} className={`${styles.card}`}>
          <div className={styles.card_container}>
            <div className={styles.card_icon} style={{ background: iconBG }}>
              <img src={item?.icon} alt="" />
            </div>
            <div className={styles.card_content}>
              <div className={`${styles.card_content_title}`}>
                {item?.title}
              </div>
              <div className={`${styles.card_content_cost}`}>{item?.cost}</div>
            </div>
          </div>
          <div className={styles.card_time}>{item?.time}</div>
        </div>
      ))}
    </div>
  );
};

export default PostofficeDashboardCard;
