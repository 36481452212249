import React from "react";
import "../../../styles/form.scss";
import InputError from "./InputError";
const InputField = (props) => {
  const { type, name, id, value, onChange, placeholder, icon, error } = props;
  return (
    <div className="form_control">
      <span className="input_field_icon">{icon}</span>
      <input
        type={type}
        name={name}
        className="input_field input_email"
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />

      {error && error[name] && <InputError error={error[name]} />}
    </div>
  );
};

export default InputField;
